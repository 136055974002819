<template>
  <div>
    <v-card min-width="180">
      <v-card-title>
        <span class="accent--text font-weight-bold ma-auto">
          {{ value.name || '--' }}
        </span>
      </v-card-title>
      <v-card-text>
        <div>
          <v-img max-height="80" max-width="80" :src="medalSrc" class="mx-auto">
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-col cols="auto">
                <div class="pb-5 accent--text font-weight-bold text-h5">
                  {{ value.ranking || '-' }}
                </div>
              </v-col>
            </v-row>
          </v-img>
        </div>

        <div class="ems-blue-grey--text font-weight-bold">
          <div class="mt-3" v-for="option in detail" :key="option.key">
            <div
              v-if="
                option.key === 'carbonEmissions' &&
                $route.name === 'CityOverview'
              "
              class="d-flex align-center"
            >
              <div>{{ showLabel(option.key) }}</div>
            </div>
            <div v-else>{{ showLabel(option.key) }}</div>
            <div class="accent--text">
              {{ showValue(option.key) | formatNumber }}
              <span class="ml-1">{{ option.unit }}</span>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    isDistRank: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      medalsIcons: {
        1: require('@/assets/image/medals/1.svg'),
        2: require('@/assets/image/medals/2.svg'),
        3: require('@/assets/image/medals/3.svg'),
        normal: require('@/assets/image/medals/normal.svg')
      },
      detail: [
        { key: 'energy', unit: 'kWh' },
        { key: 'saving', unit: 'kWh' },
        { key: 'demand', unit: 'kW' },
        { key: 'carbonEmissions', unit: 'kgCO2e' }
      ]
    }
  },
  computed: {
    medalSrc() {
      if (!this.value || !this.value.ranking) {
        return this.medalsIcons['normal']
      }
      return Number(this.value.ranking) >= 4
        ? this.medalsIcons['normal']
        : this.medalsIcons[Number(this.value.ranking)]
    }
  },
  filters: {
    formatNumber: function (value) {
      return String(value) === 'null' ? '--' : numeral(value).format('0,0')
    }
  },
  methods: {
    showLabel(key) {
      switch (key) {
        case 'energy':
          return this.isDistRank ? '全區總用電' : '校園總用電'
        case 'saving':
          return this.isDistRank ? '全區節電量' : '校園同期節電量'
        case 'demand':
          return this.isDistRank ? '總體需量' : '最大需量'
        case 'carbonEmissions':
          return '預估碳排放量'
      }
    },
    showValue(key) {
      // return key === 'carbonEmissions'
      //   ? this.value[key] * 0.905
      //   : this.value[key]
      return this.value[key]
    }
  }
}
</script>

<style></style>
