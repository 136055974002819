var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"city-overview pa-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('weatherCard',{staticClass:"header-card",attrs:{"cityId":_vm.tagId}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('energyCard',{staticClass:"header-card",attrs:{"data":_vm.eneryData}})],1)],1),_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('span',{staticClass:"font-weight-bold title accent--text"},[_vm._v("能源地圖")])]),_c('v-col',{attrs:{"cols":"6","sm":"3"}},[(_vm.pageData)?_c('span',{staticClass:"font-weight-bold title accent--text"},[_vm._v(_vm._s(_vm.pageData.displayName)+"節電排名")]):_vm._e()]),_c('v-col',{attrs:{"cols":"6","sm":"2"}}),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0","color":"#FFFFFF99"}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('TopRankInfo',{attrs:{"isDistRank":true},model:{value:(_vm.theDistData),callback:function ($$v) {_vm.theDistData=$$v},expression:"theDistData"}})],1),_c('v-col',[_c('div',{staticClass:"text-center"},[_c('gMap',{attrs:{"ranking":_vm.distRankingData,"mapCenter":_vm.distRankingData[0]
                          ? {
                              lat: _vm.distRankingData[0].latitude,
                              lng: _vm.distRankingData[0].longitude
                            }
                          : {
                              lat: 0,
                              lng: 0
                            }},on:{"input":_vm.clickMapMarker}})],1)])],1),(_vm.$route.name === 'CityOverview')?_c('div',{staticClass:"text-caption mt-2"},[_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"8"}},[_vm._v("mdi-asterisk")]),_vm._v("預估碳排放量：用電量 kWh x 電力排放係數 ")],1)]):_vm._e()],1),_c('v-col',{staticStyle:{"border-left":"1px solid #dddddd88"},attrs:{"cols":"12","sm":"5"}},[_c('PowerSavingRanking',{attrs:{"ranking":_vm.distRankingData}}),(_vm.$route.name === 'CityOverview')?_c('div',{staticClass:"text-caption"},[_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"8"}},[_vm._v("mdi-asterisk")]),_vm._v("節電量：去年該月用電量 - 今年該月用電量 ")],1),_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"8"}},[_vm._v("mdi-asterisk")]),_vm._v("上月用電量：該月總計每日用電量，每日最終用電量為當天最終累積電度-當天起始累積電度 ")],1)]):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }