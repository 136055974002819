<template>
  <v-card color="#FFFFFFB3" elevation="0">
    <v-card-text class="fill-height pa-5">
      <v-card
        class="d-flex flex-column"
        color="transparent"
        flat
        tile
        height="100%"
      >
        <v-card class="mb-auto" color="transparent" flat tile>
          <div class="text-h6 ems-blue-grey--text">上月總用電量</div>
          <div>
            <span class="text-h5 font-weight-bold accent--text">{{
              data.energy | checkNull
            }}</span>
            <span class="ml-2 text-h6 ems-blue-grey--text">kWh</span>
          </div>
        </v-card>
        <v-card class="mb-auto" color="transparent" flat tile>
          <div class="text-h6 ems-blue-grey--text">
            節能比率
            <span class="text-caption grey--text ml-2">
              ( 去年前一個月用電量 - 今年前一個月用電量 ) / 去年前一個月用電量 *
              100%</span
            >
          </div>
          <div>
            <span
              class="text-h5 font-weight-bold"
              :class="{
                'secondary--text': data.yoy >= 0,
                'red--text': data.yoy < 0
              }"
            >
              <span class="ml-2 text-h6 ems-blue-grey--text">
                {{ data.yoy >= 0 ? '減少' : '增加' }}耗能</span
              >
              {{ Math.abs((data.yoy * 100).toFixed()) }}%</span
            >
            <span
              class="text-h5 font-weight-bold ml-10"
              :class="{
                'accent--text': data.yoy >= 0,
                'red--text': data.yoy < 0
              }"
            >
              <span class="ml-2 text-h6 ems-blue-grey--text">
                用電量比去年{{ data.yoy >= 0 ? '減少' : '增加' }}</span
              >

              {{ Math.abs(data.saving) | checkNull }}</span
            >
            <span class="ml-2 text-h6 ems-blue-grey--text">kWh</span>
            <v-progress-linear
              :value="100 - parseInt(data.yoy * 100)"
              :buffer-value="100"
              height="7"
              :color="data.yoy >= 0 ? `ems-aquamarine` : `red`"
            ></v-progress-linear>
          </div>
        </v-card>
        <v-card color="transparent" flat tile>
          <div class="text-h6 ems-blue-grey--text">上月太陽能發電量</div>
          <div>
            <span class="text-h5 font-weight-bold accent--text">{{
              data.solar | checkNull
            }}</span>
            <span class="ml-2 text-h6 ems-blue-grey--text">kWh</span>
          </div>
        </v-card>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: undefined,
  mixins: [],
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      isCityPage: this.$route.name === 'CityOverview'
    }
  },
  filters: {
    checkNull(value) {
      const numberFormat = (value) => {
        return new Intl.NumberFormat('en-US', {
          maximumFractionDigits: 2
        }).format(value)
      }
      return String(value) === 'null' ? '--' : numberFormat(parseInt(value))
    }
  }
}
</script>

<style></style>
